import { QueryOptions } from '@apollo/client/core';
import { dataLoader, type RedisDataLoaderOptions } from '@root/libs/redis/dataloader/dataLoader';

import Service from '@root/common/base/Service';

import getFrontpageFeed from '@root/modules/frontpage/graphql/getFrontpageFeed.graphql';

import { FrontpageFetchState, FrontpageFetchContext } from '@root/modules/frontpage/domain';

import type { ServiceResponse } from '@root/common/types/service';
import type { FeedApiResponseRaw } from '@root/modules/feed/types';

interface FrontpageFeedServiceVariables {
  domain: string;
  authorLanguage: string;
}

export class FrontpageFeedService extends Service {
  /**
   * Fetch frontpage feed data either from redis or from API
   * Store data with redisDataLoader if data is fetched from API
   */
  public async fetch(_state: FrontpageFetchState, context: FrontpageFetchContext): ServiceResponse<FeedApiResponseRaw> {
    const channelSettings = context.channel.settings;
    const variables = {
      domain: channelSettings.domain,
      authorLanguage: channelSettings.lang.toUpperCase(),
    };

    const options: QueryOptions<FrontpageFeedServiceVariables> = Object.assign({ query: getFrontpageFeed }, { variables });
    const dataLoaderOptions: RedisDataLoaderOptions = {
      remote: {
        expireTimeMs: 30000, // 30 seconds
        gracePeriodMs: 24 * 3600 * 1000, // 24 hours
        keyPrefix: 'frontpage_feed',
      },
      local: {
        cache: true,
      },
    };

    // Create a request wrapper to handle API requests
    const requestWrapper = async (options: QueryOptions<FrontpageFeedServiceVariables>): Promise<FeedApiResponseRaw | Error> => {
      const apiProvider = this.createProvider('GraphQL');
      apiProvider.selectAPI('content-api-v3').setLinkOptions({ useAutomaticPersistedQueries: true, useGETAutomaticPersistedQueries: true });
      const response = await apiProvider.query<FeedApiResponseRaw>(options);

      this.throwGraphqlOrApolloErrorIfExists(response);

      const isSectionsEmpty = !response.data.feed?.items?.[0]?.sections?.items?.length;

      if (isSectionsEmpty) {
        throw new Error('Feed.service requestWrapper response error', {
          cause: 'empty sections',
        });
      }

      return response.data;
    };

    const redisDataLoader = dataLoader<QueryOptions<FrontpageFeedServiceVariables>, FeedApiResponseRaw | Error>(requestWrapper, dataLoaderOptions);

    // Fetch data from redis or API
    const response: FeedApiResponseRaw | Error = redisDataLoader
      ? await redisDataLoader.load(options)
      : await this.requestWrapperHandler<FeedApiResponseRaw | Error>(() => requestWrapper(options));

    // Handle internal graphql errors
    if (response instanceof Error) {
      const errorData = this.generateErrorData(response);
      return [null, errorData];
    }

    return [response, null];
  }
}
