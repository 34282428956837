import { GetChannelConfigFunction } from '@root/libs/init-channel/types';

export default function getFrontPageAdsSettings({ $channelConfig }: { $channelConfig: GetChannelConfigFunction }) {
  const { id } = $channelConfig('settings');

  // Create keywords data
  const keywords = ['channel_frontpage', `${id}_frontpage`];

  return {
    mkw: keywords,
  };
}
