import { FetchState } from '@root/common/domain';
import type { FetchState as IFetchState, FetchStateInput } from '@root/common/types/domain';
import type { Feed } from '@root/modules/feed/types';

interface IFrontpageFetchState extends IFetchState {
  feed: Feed | null;
}

export class FrontpageFetchState extends FetchState implements IFrontpageFetchState {
  private _feed: IFrontpageFetchState['feed'];

  constructor(input: FetchStateInput<IFrontpageFetchState>) {
    super(input);
    this._feed = input.feed;
  }

  get feed(): IFrontpageFetchState['feed'] {
    return this._feed;
  }

  set feed(feed: IFrontpageFetchState['feed']) {
    this._feed = feed;
  }
}
