import { FetchPage } from './FetchPage';
import { fixUrlParams } from '@root/common/utils/url';
import Meta from '@root/common/utils/Meta';

import { AdFormState } from '@ads/types/AdForm';

import { NuxtError } from '@nuxt/types';
import { SentryError } from './SentryError';
interface FixUrlParams {
  type: 'article' | 'category' | 'topic' | 'author';
  values: { id?: number | string | null; slug?: string | null };
}

export default FetchPage.extend({
  watch: {
    // Fire this check in CSR after page has fetched its content
    '$fetchState.pending'(isStillFetching, didFetch) {
      if (didFetch && !isStillFetching) {
        this.$nextTick(() => {
          this.$store.commit('setPageContentLoadTimeStamp', new Date().getMilliseconds());
        });
      }
    },
  },
  errorCaptured(err, vm, info) {
    const { componentOptions } = vm.$vnode;
    const propsData = componentOptions?.propsData;
    const componentName = vm.$vnode.componentOptions?.tag || 'unknown component';
    this.$sentry.setTag('process.type', process.server ? 'server' : 'client');
    this.$sentry.captureException(err, { contexts: { vue: { componentName, propsData, lifecycleHook: info } } });

    // Stop the propagation of errors further
    // to parent or global error handler to not
    // throw 500 because of 1 faulty block
    return false;
  },
  methods: {
    /**
     * Overwrite ads settings with custom ads settings
     */
    setPageAdsSettings(settings: Partial<AdFormState['page']>) {
      this.$store.commit('adForm/setPageAdsSettings', settings);
    },
    /**
     * Reset ads back to initial state
     */
    resetPageAdsSettings() {
      this.$store.commit('adForm/resetPageAdsSettings');
      // Force close siteHeader for smoother loading
      const siteHeaderCloseButton = document.querySelector('#delfi-adx-siteheader .closeButton') as HTMLElement;
      siteHeaderCloseButton?.click();
    },
    /**
     * Overwrite header bottom menu with custom navigation
     */
    setPageHeaderBottomNavigation(data: Record<string, any>) {
      // Use data or reset navigation back to initial state
      // Reset is needed after user navigates to category which doesn't have custom data - then we have to show initial data to user
      const navigation = data || this.$channelConfig('header')?.navigation.bottom;
      this.$store.commit('navigation/setHeaderBottomNavigation', { navigation, overwrite: !!data });
    },
    /**
     * Reset header bottom menu back to initial state
     */
    resetPageHeaderBottomNavigation() {
      const navigation = this.$channelConfig('header')?.navigation.bottom;
      this.$store.commit('navigation/setHeaderBottomNavigation', { navigation, overwrite: false });
      this.$store.commit('category/setCategoryData', null);
    },
    /**
     * Get pre generate meta data for the page
     */
    getPageMeta() {
      const meta = new Meta(this.$channelConfig('meta').base, this.$route.path);
      return meta;
    },
    /**
     * Fix page url by adding missing slug/id to the url. CSR ONLY!
     */
    fixPageUrlParams(type: FixUrlParams['type'], { id, slug }: FixUrlParams['values']) {
      if (process.server) {
        console.error('fixPageUrlParams can only be called in client side!');
        return;
      }

      const params = {
        type,
        locale: this.$channelConfig('settings').locale,
        route: this.$route,
        id,
        slug,
      };

      fixUrlParams(params);
    },
    /**
     * Redirect to Nuxt error page
     */
    handlePageError(errorData: NuxtError | SentryError<any, any>) {
      const error = {
        message: 'Unknown/unhandled error',
        statusCode: 404,
      };

      if (errorData instanceof SentryError) {
        error.message = errorData.clientMessage;
        error.statusCode = errorData.tags.responseCode;
      } else {
        error.message = errorData.message ?? error.message;
        error.statusCode = errorData.statusCode ?? error.statusCode;
      }

      if (process.server && error.statusCode >= 500) {
        this.$nuxt.context.res.setHeader('Cache-control', 'public, max-age=3');
      }

      this.$nuxt.error(error);
    },
    handlePageRedirect(redirectPath: string | null) {
      if (!redirectPath) {
        return;
      }

      try {
        this.$nuxt.context.redirect(301, redirectPath);
      } catch (e) {
        this.$sentry.captureException(`Redirect failed: ${e.message}, original url: ${this.$nuxt.context.route.fullPath}, redirect path: ${redirectPath}`, {
          tags: { 'process.server': process.server ? 'server' : 'client' },
        });
        this.$nuxt.context.redirect(301, encodeURI(redirectPath));
      }
    },
    restoreScroll() {
      window.history.scrollRestoration = 'auto';
    },
  },
});
