import { FetchHandler } from '@root/common/domain';

import { FrontpageFeedService } from '@root/modules/frontpage/services';
import { FrontpageFetchDataError } from '@root/modules/frontpage/errors';

import { FeedAdapter } from '@root/modules/feed/utils';

import { FrontpageFetchState } from './FrontpageFetchState.domain';
import { FrontpageFetchContext } from './FrontpageFetchContext.domain';

import { FetchStatus, type FetchResponse } from '@root/common/types/domain';
import type { Feed } from '@root/modules/feed/types';

type FeedFetchResponse = FetchResponse<Feed, FrontpageFetchDataError>;

export class FrontpageFetchHandler extends FetchHandler<FrontpageFetchState, FrontpageFetchContext> {
  constructor(FeedFetchState: FrontpageFetchState, FeedFetchContext: FrontpageFetchContext) {
    super(FeedFetchState, FeedFetchContext);
  }

  public async handleFetch(feedService: FrontpageFeedService): FeedFetchResponse {
    // Handle data fetching with service
    const [serviceResponse, serviceError] = await feedService.fetch(this.state, this.context);

    // Handle invalid response errors
    if (serviceError || !serviceResponse.feed?.items?.[0]?.sections?.items?.length) {
      this.state.fetchStatus = FetchStatus.Error;

      const fetchDataError = new FrontpageFetchDataError('Feed fetch error - invalid data', {
        state: this.state,
      });

      fetchDataError.tags.responseCode = serviceError?.statusCode ?? fetchDataError.tags.responseCode;
      fetchDataError.contexts.data.refetchType = this.state.refetchType;

      this.state.fetchStatus = FetchStatus.Error;
      return [null, fetchDataError];
    }

    const feedResponse = serviceResponse.feed.items[0].sections.items;

    // Adapt data to make sure it is in the correct format
    const feedAdapter = new FeedAdapter(feedResponse);
    const adaptedFeed = feedAdapter.adapt();

    // Handle successful  data fetch
    this.state.fetchStatus = FetchStatus.Success;

    return [adaptedFeed, null];
  }
}
