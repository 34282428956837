import Vue from 'vue';
import { shouldRefetch, getActiveRefetchType, updateRefetchState, addRefetchToQuery as _addRefetchToQuery } from '@root/common/utils/fetch/refetch';
import { handleFetchError } from '@root/common/utils/handleFetchError';

import { SentryError } from './SentryError';

import { FetchStatus, RefetchType, type RefetchState } from '@root/common/types/domain';

interface Data {
  fetchStatus: FetchStatus;
  refetch: RefetchState;
}
interface Methods {
  finishRefetch: () => void;
  errorRefetch: () => boolean;
  addRefetchToQuery: (activeType: RefetchType) => void;
  startFetching: (fetchStatus: FetchStatus) => void;
  stopFetching: (fetchStatus: FetchStatus) => void;
  handleFetchError: (fetchStatus: FetchStatus, error: SentryError<any, any>) => boolean;
}

export const FetchPage = Vue.extend<Data, Methods, unknown, unknown>({
  data() {
    return {
      fetchStatus: FetchStatus.Initial,
      refetch: {
        activeType: null,
        query: [],
      },
    };
  },
  watch: {
    /**
     * Handle page refetch queries
     * Watch for refetch query changes and trigger refetch if needed
     */
    refetch: {
      async handler(refetch: RefetchState) {
        if (!shouldRefetch(this.fetchStatus, refetch)) {
          return;
        }

        await this.$nextTick();
        this.refetch.activeType = getActiveRefetchType(refetch);

        this.$sentry.addBreadcrumb({
          category: 'Page',
          message: `Refetch: ${this.refetch.activeType}`,
        });

        if (this.$fetchState.pending) {
          // Waits for the current fetch to finish, but does not call fetch again
          await this.$fetch();
        }

        this.$fetch();
      },
      deep: true,
    },
  },
  methods: {
    /*
     * Call this method after fetch method is successfully finished
     */
    finishRefetch() {
      if (this.refetch.activeType === null) {
        return;
      }

      this.refetch = updateRefetchState(this.refetch, { remove: this.refetch.activeType });
    },
    /*
     * Call this method when fetch method encounters an error
     */
    errorRefetch() {
      if (process.server || this.refetch.activeType === RefetchType.Error) {
        return false;
      }

      this.refetch = updateRefetchState(this.refetch, { add: RefetchType.Error, remove: this.refetch.activeType });
      return true;
    },
    /*
     * Add refetch type to query to be called after initial fetch
     */
    addRefetchToQuery(activeType: RefetchType) {
      this.refetch.query = _addRefetchToQuery(this.refetch.query, activeType);
    },
    /**
     * Call before FetchHandler.handleFetch method to set vue fetch status to pending
     */
    startFetching(fetchStatus: FetchStatus) {
      // Set fetch status to pending
      this.fetchStatus = fetchStatus;

      this.$sentry.addBreadcrumb({
        category: 'Page',
        message: `FetchStatus: ${fetchStatus}`,
      });
    },
    /**
     * Call after successful fetch to set vue fetch status to success
     * Handle refetch queries
     */
    stopFetching(fetchStatus: FetchStatus) {
      // Set fetch status to success
      this.fetchStatus = fetchStatus;
      this.finishRefetch();

      this.$sentry.addBreadcrumb({
        category: 'Page',
        message: `FetchStatus: ${fetchStatus}`,
      });
    },
    /**
     * Call after fetch error to set vue fetch status to error
     * Log error to sentry and refetch data if needed
     */
    handleFetchError(fetchStatus: FetchStatus, sentryError: SentryError<any, any>) {
      // Set fetch status to error
      this.fetchStatus = fetchStatus;

      this.$sentry.addBreadcrumb({
        category: 'Page',
        message: `FetchStatus: ${fetchStatus}`,
      });

      const [error, sentryData] = handleFetchError(sentryError);
      this.$sentry.captureException(error, sentryData);

      return this.errorRefetch();
    },
  },
});
