import type { RootContext as IRootContext, RootContextKeys, RootContextInput } from '@root/common/types/domain';

/**
 * Master context for handling application domain context.
 * Ensures domain context data is always typed correctly throughout the application.
 * Exposes predetermined data based on the specified context key.
 *
 * @template T - The type of context key ('channel', 'route', 'customer').
 */
export class RootContext<T extends RootContextKeys> implements IRootContext<T> {
  private _channel!: IRootContext<T>['channel'];
  private _route!: IRootContext<T>['route'];
  private _customer!: IRootContext<T>['customer'];

  constructor(input: RootContextInput<T>) {
    if ('channel' in input) {
      this._channel = input.channel as IRootContext<T>['channel'];
    }

    if ('route' in input) {
      this._route = input.route as IRootContext<T>['route'];
    }

    if ('customer' in input) {
      this._customer = input.customer as IRootContext<T>['customer'];
    }
  }

  get channel(): IRootContext<T>['channel'] {
    return this._channel;
  }

  set channel(channel: IRootContext<T>['channel']) {
    this._channel = channel;
  }

  get route(): IRootContext<T>['route'] {
    return this._route;
  }

  set route(route: IRootContext<T>['route']) {
    this._route = route;
  }

  get customer(): IRootContext<T>['customer'] {
    return this._customer;
  }

  set customer(customer: IRootContext<T>['customer']) {
    this._customer = customer;
  }
}
