import { SentryError, type ContextInner, type ServiceErrorTags, type SentryData } from '@root/common/base/SentryError';

interface ServiceErrorObject {
  message?: string;
  statusCode?: string;
  extraData?: string;
}

type ErrorInput = ServiceErrorObject | SentryError<ContextInner, ServiceErrorTags> | Error;

type HandleError = (value: ErrorInput) => [ErrorInput, SentryData?];

/**
 * Handle fetch errors by checking if the error is an instance of SentryError.
 * If it is, serializes the Sentry data and return it along with the error.
 * Otherwise, return the error with undefined Sentry data.
 */
export const handleFetchError: HandleError = (value: ErrorInput) => {
  if (value instanceof SentryError) {
    const sentryData = value.serializeSentryData();
    return [value, sentryData];
  }

  return [value];
};
