import { SentryError, type ErrorContext, type ErrorTags, type ServiceErrorTags } from '@root/common/base/SentryError';
import { FrontpageFetchState } from '@root/modules/frontpage/domain';

import { FrontpageFetchErrorReason } from './FrontpageFetchReason.error';

interface ArticleFetchErrorContext {
  cause: {
    reason: FrontpageFetchErrorReason.NoData;
  };
  data: {
    feed: FrontpageFetchState['feed'];
    refetchType: FrontpageFetchState['refetchType'];
  };
}

export class FrontpageFetchDataError extends SentryError<ArticleFetchErrorContext, ServiceErrorTags> {
  constructor(message: string, { state }: { state: FrontpageFetchState }) {
    super(message);
    this.clientMessage = 'error.page_not_found';

    const sentryContext: ErrorContext<ArticleFetchErrorContext> = {
      cause: {
        reason: FrontpageFetchErrorReason.NoData,
      },
      data: {
        feed: state.feed,
        refetchType: state.refetchType,
      },
    };

    const sentryTags: ErrorTags<ServiceErrorTags> = {
      apiType: 'content',
      responseCode: 500,
    };

    this.setInitialContexts(sentryContext);
    this.setInitialTags(sentryTags);
  }
}
