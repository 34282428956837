import { RootContext } from './RootContext.domain';
import { FetchState } from './FetchState.domain';
import { FetchStatus, type RootContextKeys, type FetchSuccessResponse, type FetchErrorResponse } from '../types/domain';
import { SentryError } from '../base/SentryError';

export class FetchHandler<STATE extends FetchState, CTX extends RootContext<RootContextKeys>> {
  private _state: STATE;
  private _context: CTX;

  constructor(FetchState: STATE, FetchContext: CTX) {
    this._state = FetchState;
    this._state.fetchStatus = FetchStatus.Pending;
    this._context = FetchContext;
  }

  get state(): STATE {
    return this._state;
  }

  set state(state: STATE) {
    this._state = state;
  }

  get context(): CTX {
    return this._context;
  }

  set context(context: CTX) {
    this._context = context;
  }

  protected errorResponse<T extends SentryError<any, any>>(SentryError: T): FetchErrorResponse<T> {
    SentryError.contexts.data.refetchType = this.state.refetchType;
    this.state.fetchStatus = FetchStatus.Error;

    return [null, SentryError];
  }

  protected successResponse<T>(data: T): FetchSuccessResponse<T> {
    this.state.fetchStatus = FetchStatus.Success;

    return [data, null];
  }
}
