import { RootContext } from '@root/common/domain';
import type { RootContextInput } from '@root/common/types/domain';

type FrontpageExposeContext = 'channel';

export class FrontpageFetchContext extends RootContext<FrontpageExposeContext> {
  constructor(input: RootContextInput<FrontpageExposeContext>) {
    super(input);
  }
}
