import { FetchStatus } from '@root/common/types/domain';
import type { FetchState as IFetchState, FetchStateInput } from '@root/common/types/domain';

export class FetchState implements IFetchState {
  private _fetchStatus: IFetchState['fetchStatus'] = FetchStatus.Initial;
  private _refetchType: IFetchState['refetchType'] = null;

  constructor(input: FetchStateInput<IFetchState>) {
    this.refetchType = input?.refetchType || null;
  }

  get fetchStatus(): IFetchState['fetchStatus'] {
    return this._fetchStatus;
  }

  set fetchStatus(fetchStatus: IFetchState['fetchStatus']) {
    this._fetchStatus = fetchStatus;
  }

  get refetchType(): IFetchState['refetchType'] {
    return this._refetchType;
  }

  set refetchType(refetchType: IFetchState['refetchType']) {
    this._refetchType = refetchType;
  }
}
