
import Page from '@root/common/base/Page';
import { FrontpageFetchContext, FrontpageFetchState, FrontpageFetchHandler } from '@root/modules/frontpage/domain';
import { FrontpageFeedService } from '@root/modules/frontpage/services';

import FeedParser from '@root/common/components/helpers/FeedParser.vue';
import GridSkeletonLoader from '@root/common/components/base/loader/GridSkeletonLoader.vue';
import getFrontPageAdsSettings from '@root/modules/frontpage/utils/getFrontPageAdsSettings';

import type { Feed } from '@root/modules/feed/types';
import { RefetchType } from '~/src/common/types/domain';

interface Data {
  feed: Feed;
}

export default Page.extend<Data, unknown, unknown, unknown>({
  components: {
    FeedParser,
    GridSkeletonLoader,
  },
  beforeRouteLeave(to, from, next) {
    this.resetPageAdsSettings();
    next();
  },
  data() {
    return {
      feed: [],
    };
  },
  async fetch() {
    const feedFetchState = new FrontpageFetchState({ refetchType: this.refetch.activeType, feed: this.feed });
    const feedFetchContext = new FrontpageFetchContext({ channel: this.$channelConfig('config') });

    const feedFetchHandler = new FrontpageFetchHandler(feedFetchState, feedFetchContext);

    // Fetch data
    this.startFetching(feedFetchHandler.state.fetchStatus);
    const feedService = new FrontpageFeedService();
    const [feed, feedError] = await feedFetchHandler.handleFetch(feedService);

    if (feedError) {
      // Try 1 refetch before using stored data as fallback
      const willRefetch = this.handleFetchError(feedFetchHandler.state.fetchStatus, feedError);

      if (willRefetch) {
        return;
      }

      const storedFeed = this.$store.getters['frontpage/getTempData'];

      // Show error page when no stored data
      if (!storedFeed) {
        this.handlePageError(feedError);
        return;
      }

      // Fallback to store data if fetch fails
      this.feed = storedFeed;
      return;
    }

    // Set feed for rendering
    this.$store.commit('frontpage/setTempData', feed);
    this.feed = feed;

    // Set frontpage specific ads
    const frontpageAds = getFrontPageAdsSettings({ $channelConfig: this.$channelConfig });
    this.setPageAdsSettings(frontpageAds);

    // Handle successful fetch
    this.stopFetching(feedFetchHandler.state.fetchStatus);
  },
  mounted() {
    this.restoreScroll();
  },
  computed: {
    refetchHeadlines() {
      return this.$store.state.frontpage.refetch;
    },
  },
  watch: {
    refetchHeadlines(refetch) {
      if (refetch) {
        window.scrollTo(0, 0);
        this.addRefetchToQuery(RefetchType.Force);
        this.$store.commit('frontpage/setRefetchState', false);
      }
    },
  },
});
