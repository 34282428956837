import { FetchStatus, RefetchType, type RefetchState } from '@root/common/types/domain';

export const addRefetchToQuery = <Key extends RefetchType>(query: RefetchState['query'], activeType: Key) => {
  if (query.includes(activeType)) {
    return query;
  }

  return [...query, activeType];
};

export const removeRefetchFromQuery = <Key extends Exclude<RefetchState['activeType'], null>>(query: RefetchState['query'], activeType: Key) => {
  return query.filter((item) => item !== activeType);
};

export const updateRefetchState = (refetch: RefetchState, task: { add?: RefetchType | null; remove?: RefetchType | null }): RefetchState => {
  if (process.server && !(task.add && task.remove)) {
    return refetch;
  }

  let query = [...refetch.query];

  if (task.add && !refetch.query.includes(task.add)) {
    query = addRefetchToQuery(query, task.add);
  }

  if (task.remove && refetch.query.includes(task.remove)) {
    query = removeRefetchFromQuery(query, task.remove);
  }

  return {
    activeType: null,
    query,
  };
};

export const getActiveRefetchType = (refetch: RefetchState): RefetchState['activeType'] => {
  return refetch.query[0] || null;
};

export const shouldRefetch = (fetchStatus: FetchStatus, refetch: RefetchState): boolean => {
  const isFetchPending = fetchStatus === FetchStatus.Pending;
  const refetchAmount = refetch.query.length;

  return !(refetch.activeType || isFetchPending || refetchAmount === 0);
};
